import { Injectable } from '@angular/core';

import { LogDiagnoseService } from './services';

@Injectable({
    providedIn: 'root',
})
export class SupportWebSupportService {
    constructor(private logDiagnoseService: LogDiagnoseService) {}

    get logDiagnose(): LogDiagnoseService {
        return this.logDiagnoseService;
    }
}
