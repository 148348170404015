import * as appState from 'app/state/app.state';

import { SupportLogDiagnoseDto } from '../../../../../shared/services/support/interfaces';

export interface AppState extends appState.AppState {
    LogDiagnoseState: LogDiagnoseState;
}

export interface LogDiagnoseState {
    logDiagnoseItems: SupportLogDiagnoseDto[];
    isLoading: boolean;
    hasLoadingFailed: boolean;
    loadError: Error | undefined;
}

export const initialLogDiagnoseState: LogDiagnoseState = {
    logDiagnoseItems: [],
    isLoading: false,
    hasLoadingFailed: false,
    loadError: undefined,
};
