import { Action, createReducer, on } from '@ngrx/store';

import { MODULE_ID_PLATFORM_SERVICE } from 'app/shared/services/internal/edge-static-info/edge-static-info-provider.data';
import { PartnerRestartModuleResult } from 'app/shared/services/partner';
import { PlatformServiceReportedPropertiesDto } from 'app/shared/services/web-support/edge/platformService.model';
import { EdgeGatewayDetailsDto } from 'app/shared/services/web-support/gateway.model';

import { GatewayAction } from '.';
import { GatewayState, initialGatewayState } from './gateway.state';

const reducer = createReducer<GatewayState>(
    initialGatewayState,

    on(GatewayAction.readGatewayDetails, (state) => {
        return {
            ...state,
            isLoading: true,
            isLoadingProjectTopology: false,
            hasLoadingFailed: true,
            diagnoseResults: {},
            edgeGatewayDetails: undefined,
            projectTopology: undefined,
            projectType: undefined,
        };
    }),
    on(GatewayAction.readGatewayDetailsSuccess, (state, action) => {
        return {
            ...state,
            selectedGateway: action.gatewayDetails,
            edgeGatewayDetails: undefined,
            platformServiceReportedProperties: undefined,
            isLoading: false,
            isLoadingProjectTopology: false,
            hasLoadingFailed: false,
        };
    }),
    on(GatewayAction.readGatewayDetailsFailed, (state) => {
        return {
            ...state,
            selectedGateway: undefined,
            edgeGatewayDetails: undefined,
            platformServiceReportedProperties: undefined,
            isLoading: false,
            isLoadingProjectTopology: false,
            hasLoadingFailed: true,
        };
    }),

    on(GatewayAction.readEdgeGatewayDetails, (state) => {
        return {
            ...state,
            edgeGatewayDetails: undefined,
            platformServiceReportedProperties: undefined,
            isLoadingDetails: true,
            hasLoadingFailed: false,
        };
    }),
    on(GatewayAction.readEdgeGatewayDetailsSuccess, (state, action) => {
        return {
            ...state,
            edgeGatewayDetails: action.edgeGatewayDetails,
            platformServiceReportedProperties: getPlatformServiceReportedProperties(
                action.edgeGatewayDetails,
            ),
            diagnoseResults: {},
            isLoadingDetails: false,
            hasLoadingFailed: false,
        };
    }),
    on(GatewayAction.readEdgeGatewayDetailsFailed, (state) => {
        return {
            ...state,
            edgeGatewayDetails: undefined,
            platformServiceReportedProperties: undefined,
            isLoadingDetails: false,
            hasLoadingFailed: true,
        };
    }),

    on(GatewayAction.pingGateway, (state) => {
        return {
            ...state,
            pingEdgeAgentResult: 0,
            isPingingEdgeGateway: true,
        };
    }),
    on(GatewayAction.pingGatewaySuccess, (state, action) => {
        return {
            ...state,
            pingEdgeAgentResult: action.resultCode,
            isPingingEdgeGateway: false,
        };
    }),
    on(GatewayAction.pingGatewayFailed, (state) => {
        return {
            ...state,
            pingEdgeAgentResult: -1,
            isPingingEdgeGateway: false,
        };
    }),
    on(GatewayAction.startDiagnose, (state) => {
        return {
            ...state,
            isDiagnosing: true,
            diagnoseResults: {},
        };
    }),
    on(GatewayAction.startDiagnoseFailed, (state) => {
        return {
            ...state,
            isDiagnosing: false,
            diagnoseResults: {},
        };
    }),
    on(GatewayAction.stopDiagnose, (state) => {
        return {
            ...state,
            isDiagnosing: false,
        };
    }),
    on(GatewayAction.diagnoseGatewayUpdate, (state, action) => {
        if (action.diagnoseResult.gatewayId !== state.selectedGateway?.gateway.id) {
            return {
                ...state,
            };
        }

        const newDiagnoseResults = { ...state.diagnoseResults };
        if (action.diagnoseResult.moduleId) {
            newDiagnoseResults[action.diagnoseResult.moduleId] = action.diagnoseResult;
        }

        return {
            ...state,
            diagnoseResults: newDiagnoseResults,
        };
    }),
    on(GatewayAction.initializeInfrastructureModuleViews, (state, action) => {
        return {
            ...state,
            infrastructureModulesViews: action.views,
        };
    }),
    on(GatewayAction.selectInfrastructureModuleView, (state, action) => {
        const view = state.infrastructureModulesViews.find((x) => x.id === action.selectedViewId);
        return {
            ...state,
            selectedInfrastructureModuleView: view,
        };
    }),
    on(GatewayAction.getProjectTopology, (state) => {
        return {
            ...state,
            projectTopology: undefined,
            isLoadingProjectTopology: true,
            hasLoadingProjectTopologyFailed: false,
        };
    }),
    on(GatewayAction.getProjectTopologySuccess, (state, action) => {
        return {
            ...state,
            projectTopology: action.projectTopology,
            isLoadingProjectTopology: false,
            hasLoadingProjectTopologyFailed: false,
        };
    }),
    on(GatewayAction.getProjectTopologyFailed, (state) => {
        return {
            ...state,
            projectTopology: undefined,
            isLoadingProjectTopology: false,
            hasLoadingProjectTopologyFailed: true,
        };
    }),
    on(GatewayAction.getProjectType, (state) => {
        return {
            ...state,
            projectType: undefined,
        };
    }),
    on(GatewayAction.getProjectTypeSuccess, (state, action) => {
        return {
            ...state,
            projectType: action.projectType,
        };
    }),
    on(GatewayAction.getProjectTypeFailed, (state) => {
        return {
            ...state,
            projectType: undefined,
        };
    }),

    on(GatewayAction.restartModule, (state) => {
        return {
            ...state,
            isRestartingModule: true,
            restartModuleResult: {
                edgeHubRestarted: false,
                moduleRestarted: false,
            } as PartnerRestartModuleResult,
        };
    }),
    on(GatewayAction.restartModuleSuccess, (state, action) => {
        return {
            ...state,
            isRestartingModule: false,
            restartModuleResult: action.restartResult,
        };
    }),
    on(GatewayAction.restartModuleFailed, (state, action) => {
        return {
            ...state,
            isRestartingModule: false,
            restartModuleResult: action.restartResult,
        };
    }),
    on(GatewayAction.selectDiagnoseResult, (state, action) => {
        return {
            ...state,
            selectedDiagnoseResult: action.dialogResult,
            restartModuleResult: undefined,
        };
    }),
);

export function gatewayReducer(state: GatewayState | undefined, action: Action) {
    return reducer(state, action);
}

function getPlatformServiceReportedProperties(
    edgeGatewayDetails: EdgeGatewayDetailsDto,
): PlatformServiceReportedPropertiesDto | undefined {
    const propertiesJson = edgeGatewayDetails.moduleReportedProperties.find(
        (x) => x.key === MODULE_ID_PLATFORM_SERVICE,
    )?.value;
    return propertiesJson ? (JSON.parse(propertiesJson) as PlatformServiceReportedPropertiesDto) : undefined;
}
