import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';

import { PrivaNotificationsService } from '@priva/components/notifications';

import { SupportWebSupportService } from 'app/shared/services/support';

import { LogDiagnoseAction } from '.';

@Injectable({
    providedIn: 'root',
})
export class LogDiagnoseEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly webSupportService: SupportWebSupportService,
        private readonly notificationsService: PrivaNotificationsService,
    ) {}

    public readLogDiagnoseItems$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LogDiagnoseAction.readLogDiagnoseItems),
            switchMap((_action) => {
                return this.webSupportService.logDiagnose.getAllLogDiagnoseItems().pipe(
                    map((logDiagnoseItems) => {
                        return LogDiagnoseAction.readLogDiagnoseItemsSuccess({
                            logDiagnoseItems: logDiagnoseItems,
                        });
                    }),
                    catchError((error: Error) => {
                        return of(LogDiagnoseAction.readLogDiagnoseItemsFailed({ loadError: error }));
                    }),
                );
            }),
        );
    });

    public createLogDiagnoseItem$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LogDiagnoseAction.createLogDiagnoseItem),
            switchMap((action) => {
                return this.webSupportService.logDiagnose.createLogDiagnoseItem(action.logDiagnoseItem).pipe(
                    map((createdItem) => {
                        return LogDiagnoseAction.createLogDiagnoseItemSuccess({
                            logDiagnoseItem: createdItem,
                        });
                    }),
                    catchError((error: Error) => {
                        return of(LogDiagnoseAction.createLogDiagnoseItemFailed({ loadError: error }));
                    }),
                );
            }),
        );
    });

    public updateLogDiagnoseItem$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LogDiagnoseAction.updateLogDiagnoseItem),
            switchMap((action) => {
                return this.webSupportService.logDiagnose.updateLogDiagnoseItem(action.logDiagnoseItem).pipe(
                    map(() => {
                        return LogDiagnoseAction.updateLogDiagnoseItemSuccess({
                            logDiagnoseItem: action.logDiagnoseItem,
                        });
                    }),
                    catchError((error: Error) => {
                        return of(LogDiagnoseAction.updateLogDiagnoseItemFailed({ loadError: error }));
                    }),
                );
            }),
        );
    });

    public deleteLogDiagnoseItem$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(LogDiagnoseAction.deleteLogDiagnoseItem),
            switchMap((action) => {
                return this.webSupportService.logDiagnose.deleteLogDiagnoseItem(action.logDiagnoseItem).pipe(
                    map(() => {
                        return LogDiagnoseAction.deleteLogDiagnoseItemSuccess({
                            logDiagnoseItem: action.logDiagnoseItem,
                        });
                    }),
                    catchError((error: Error) => {
                        return of(LogDiagnoseAction.deleteLogDiagnoseItemFailed({ loadError: error }));
                    }),
                );
            }),
        );
    });

    public createLogDiagnoseItemFailed$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LogDiagnoseAction.createLogDiagnoseItemFailed),
                tap(() =>
                    this.notificationsService.toaster.error('_Creating the log diagnose item failed', {
                        timeout: 5000,
                    }),
                ),
            );
        },
        {
            dispatch: false,
        },
    );
    public updateLogDiagnoseItemFailed$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(LogDiagnoseAction.updateLogDiagnoseItemFailed),
                tap(() =>
                    this.notificationsService.toaster.error('_Updating the log diagnose item failed', {
                        timeout: 5000,
                    }),
                ),
            );
        },
        {
            dispatch: false,
        },
    );
}
