import { DirectMethodCollection } from './edge-static-info-provider.model';
import { EdgeModuleIdModuleNameMapping } from './edge-static-info-provider.model';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Modules
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// The moduleId values are case-sensitive.
export const MODULE_ID_EDGE_AGENT = '$edgeAgent';
export const MODULE_ID_EDGE_HUB = '$edgeHub';
export const MODULE_ID_ALARM_MODULE = 'AlarmModule';
export const MODULE_ID_COMPRINET_CONNECTOR = 'ComprinetConnector';
export const MODULE_ID_CONNEXTOR = 'Connextor';
export const MODULE_ID_DDS_CONNECTOR = 'DdsConnector';
export const MODULE_ID_FIRMWARE_UPDATER = 'FirmwareUpdater';
export const MODULE_ID_HISTORIAN = 'Historian';
export const MODULE_ID_HTTP_CONNECTOR = 'HttpConnector';
export const MODULE_ID_LEAF_DEVICE_MANAGER = 'LeafDeviceManager';
export const MODULE_ID_METADATA = 'Metadata';
export const MODULE_ID_METADATA_SERVICE = 'MetadataService';
export const MODULE_ID_MQTT_CONNECTOR = 'MqttConnector';
export const MODULE_ID_OPERATOR_API = 'OperatorApi';
export const MODULE_ID_PLATFORM_SERVICE = 'PlatformService';
export const MODULE_ID_RDC_MODULE = 'RdcModule';
export const MODULE_ID_REMOTE_MANAGEMENT = 'RemoteManagement';
export const MODULE_ID_REQUEST_ROUTER = 'RequestRouter';
export const MODULE_ID_SYSTEM_AGENT = 'SystemAgent';
export const MODULE_ID_AUTH = 'Auth';
export const MODULE_ID_METRICS_COLLECTOR = 'MetricsCollector';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Module Names
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// The moduleName values are case sensitive.
export const MODULE_NAME_EDGE_AGENT = 'edgeAgent';
export const MODULE_NAME_EDGE_HUB = 'edgeHub';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Direct Methods
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// The methodName values are case-sensitive.
export const METHOD_NAME_PING = 'ping';
export const METHOD_NAME_EDGE_AGENT_RESTART_MODULE = 'RestartModule';
export const METHOD_NAME_ALARM_MODULE_GET_ACTIVE_ALARMS = 'getActiveAlarms';
export const METHOD_NAME_CONNEXTOR_GET_ALARMS = 'getAlarms';
export const METHOD_NAME_CONNEXTOR_GET_CONFIGURED_DEVICES = 'GetConfiguredDevices';
export const METHOD_NAME_CONNEXTOR_GET_CPCS = 'getCpcs';
export const METHOD_NAME_CONNEXTOR_GET_MODULE_STATE = 'getModuleState';
export const METHOD_NAME_CONNEXTOR_GET_SUBSCRIPTION_STATE = 'getSubscriptionState';
export const METHOD_NAME_DDS_CONNECTOR_GET_MODULE_STATE = 'getModuleState';
export const METHOD_NAME_HISTORIAN_DIAGNOSTICS = 'diagnostics';
export const METHOD_NAME_HISTORIAN_SYNCHRONIZATION_STATUS = 'SynchronizationStatus';
export const METHOD_NAME_HTTP_CONNECTOR_CLEAR_EVENT_LOG = 'clearEventLog';
export const METHOD_NAME_LEAF_DEVICE_MANAGER_GET_DEVICES = 'getDevices';
export const METHOD_NAME_METADATA_SERVICE_IMPORT_STATUS = 'ImportStatus';
export const METHOD_NAME_REMOTE_MANAGEMENT_CHECK_CONFIGURED_PROJECT = 'checkConfiguredProject';
export const METHOD_NAME_REMOTE_MANAGEMENT_GET_DIAGNOSTIC_DATAPOINTS = 'getDiagnosticDatapoints';
export const METHOD_NAME_REMOTE_MANAGEMENT_GET_LOG_LEVEL = 'getLogLevel';
export const METHOD_NAME_REMOTE_MANAGEMENT_GET_PROJECT_TOPOLOGY = 'getProjectTopology';
export const METHOD_NAME_REMOTE_MANAGEMENT_GET_PROJECT_TYPE = 'getProjectType';
export const METHOD_NAME_REMOTE_MANAGEMENT_GET_SYSLOG = 'getSyslog';
export const METHOD_NAME_REMOTE_MANAGEMENT_SET_LOG_LEVEL = 'setLogLevel';

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Direct Method constants
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const METHOD_CONNEXTOR_GET_ALARMS_INPUT_LANGUAGE_DEFAULT = 'en';

// The historian module diagnostics command names are case-sensitive.
export const METHOD_HISTORIAN_DIAGNOSTICS_COMMAND_NAME_SYNCHRONIZATION_STATUS = 'SynchronizationStatus';

/**
 * Contains the mappings between moduleId and moduleName for
 * modules for which the moduleId and moduleName are not the same.
 */
export const EDGE_MODULE_ID_MODULE_NAME_MAPPINGS: EdgeModuleIdModuleNameMapping[] = [
    {
        moduleId: MODULE_ID_EDGE_AGENT,
        moduleName: MODULE_NAME_EDGE_AGENT,
    },
    {
        moduleId: MODULE_ID_EDGE_HUB,
        moduleName: MODULE_NAME_EDGE_HUB,
    },
];

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Direct Method Collections
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const EDGE_DIRECT_METHOD_COLLECTIONS: DirectMethodCollection[] = [
    {
        moduleId: MODULE_ID_EDGE_AGENT,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.NAME',
                methodName: METHOD_NAME_PING,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.DESCRIPTION',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.EDGE_AGENT.RESTART_MODULE.NAME',
                methodName: METHOD_NAME_EDGE_AGENT_RESTART_MODULE,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.EDGE_AGENT.RESTART_MODULE.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_ALARM_MODULE,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.ALARM_MODULE.GET_ACTIVE_ALARMS.NAME',
                methodName: METHOD_NAME_ALARM_MODULE_GET_ACTIVE_ALARMS,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.ALARM_MODULE.GET_ACTIVE_ALARMS.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_COMPRINET_CONNECTOR,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.NAME',
                methodName: METHOD_NAME_PING,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_CONNEXTOR,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.CONNEXTOR.GET_ALARMS.NAME',
                methodName: METHOD_NAME_CONNEXTOR_GET_ALARMS,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.CONNEXTOR.GET_ALARMS.DESCRIPTION',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.CONNEXTOR.GET_CONFIGURED_DEVICES.NAME',
                methodName: METHOD_NAME_CONNEXTOR_GET_CONFIGURED_DEVICES,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.CONNEXTOR.GET_CONFIGURED_DEVICES.DESCRIPTION',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.CONNEXTOR.GET_CPCS.NAME',
                methodName: METHOD_NAME_CONNEXTOR_GET_CPCS,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.CONNEXTOR.GET_CPCS.DESCRIPTION',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.CONNEXTOR.GET_MODULE_STATE.NAME',
                methodName: METHOD_NAME_CONNEXTOR_GET_MODULE_STATE,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.CONNEXTOR.GET_MODULE_STATE.DESCRIPTION',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.CONNEXTOR.GET_SUBSCRIPTION_STATE.NAME',
                methodName: METHOD_NAME_CONNEXTOR_GET_SUBSCRIPTION_STATE,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.CONNEXTOR.GET_SUBSCRIPTION_STATE.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_DDS_CONNECTOR,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.DDS_CONNECTOR.GET_MODULE_STATE.NAME',
                methodName: METHOD_NAME_DDS_CONNECTOR_GET_MODULE_STATE,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.DDS_CONNECTOR.GET_MODULE_STATE.DESCRIPTION',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.NAME',
                methodName: METHOD_NAME_PING,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_FIRMWARE_UPDATER,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.NAME',
                methodName: METHOD_NAME_PING,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_HISTORIAN,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.HISTORIAN.DIAGNOSTICS.NAME',
                methodName: METHOD_NAME_HISTORIAN_DIAGNOSTICS,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.HISTORIAN.DIAGNOSTICS.DESCRIPTION',
                maxVersion: '1.7.24',
            },
            {
                name: 'APP.GATEWAY.GATEWAY_DETAILS.EDGE_DETAILS_DIRECT_METHODS_TAB.DIRECT_METHODS_SECTION.DIAGNOSTICS.DIAGNOSTICS_INPUT.INFO.SYNCHRONIZATION_STATUS.NAME',
                methodName: METHOD_NAME_HISTORIAN_SYNCHRONIZATION_STATUS,
                description:
                    'APP.GATEWAY.GATEWAY_DETAILS.EDGE_DETAILS_DIRECT_METHODS_TAB.DIRECT_METHODS_SECTION.DIAGNOSTICS.DIAGNOSTICS_INPUT.INFO.SYNCHRONIZATION_STATUS.DESCRIPTION',
                minVersion: '1.7.25',
            },
        ],
    },
    {
        moduleId: MODULE_ID_HTTP_CONNECTOR,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.HTTP_CONNECTOR.CLEAR_EVENT_LOG.NAME',
                methodName: METHOD_NAME_HTTP_CONNECTOR_CLEAR_EVENT_LOG,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.HTTP_CONNECTOR.CLEAR_EVENT_LOG.DESCRIPTION',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.NAME',
                methodName: METHOD_NAME_PING,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_LEAF_DEVICE_MANAGER,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.LEAF_DEVICE_MANAGER.GET_DEVICES.NAME',
                methodName: METHOD_NAME_LEAF_DEVICE_MANAGER_GET_DEVICES,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.LEAF_DEVICE_MANAGER.GET_DEVICES.DESCRIPTION',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.NAME',
                methodName: METHOD_NAME_PING,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_METADATA,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.NAME',
                methodName: METHOD_NAME_PING,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_METADATA_SERVICE,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.METADATA_SERVICE.IMPORT_STATUS.NAME',
                methodName: METHOD_NAME_METADATA_SERVICE_IMPORT_STATUS,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.METADATA_SERVICE.IMPORT_STATUS.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_OPERATOR_API,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.NAME',
                methodName: METHOD_NAME_PING,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_PLATFORM_SERVICE,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.NAME',
                methodName: METHOD_NAME_PING,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_RDC_MODULE,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.NAME',
                methodName: METHOD_NAME_PING,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.DESCRIPTION',
            },
        ],
    },
    {
        moduleId: MODULE_ID_REMOTE_MANAGEMENT,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.NAME',
                methodName: METHOD_NAME_PING,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.DESCRIPTION',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.REMOTE_MANAGEMENT.CHECK_CONFIGURED_PROJECT.NAME',
                methodName: METHOD_NAME_REMOTE_MANAGEMENT_CHECK_CONFIGURED_PROJECT,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.REMOTE_MANAGEMENT.CHECK_CONFIGURED_PROJECT.DESCRIPTION',
                minVersion: '3.3.0',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.REMOTE_MANAGEMENT.GET_DIAGNOSTIC_DATAPOINTS.NAME',
                methodName: METHOD_NAME_REMOTE_MANAGEMENT_GET_DIAGNOSTIC_DATAPOINTS,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.REMOTE_MANAGEMENT.GET_DIAGNOSTIC_DATAPOINTS.DESCRIPTION',
                minVersion: '3.3.0',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.REMOTE_MANAGEMENT.GET_PROJECT_TOPOLOGY.NAME',
                methodName: METHOD_NAME_REMOTE_MANAGEMENT_GET_PROJECT_TOPOLOGY,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.REMOTE_MANAGEMENT.GET_PROJECT_TOPOLOGY.DESCRIPTION',
                minVersion: '3.3.0',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.REMOTE_MANAGEMENT.GET_SYSLOG.NAME',
                methodName: METHOD_NAME_REMOTE_MANAGEMENT_GET_SYSLOG,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.REMOTE_MANAGEMENT.GET_SYSLOG.DESCRIPTION',
                minVersion: '3.3.0',
            },
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.REMOTE_MANAGEMENT.SET_LOG_LEVEL.NAME',
                methodName: METHOD_NAME_REMOTE_MANAGEMENT_SET_LOG_LEVEL,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.REMOTE_MANAGEMENT.SET_LOG_LEVEL.DESCRIPTION',
                minVersion: '3.3.0',
            },

            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.REMOTE_MANAGEMENT.GET_PROJECT_TYPE.NAME',
                methodName: METHOD_NAME_REMOTE_MANAGEMENT_GET_PROJECT_TYPE,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.REMOTE_MANAGEMENT.GET_PROJECT_TYPE.DESCRIPTION',
                minVersion: '3.3.0',
            },
        ],
    },
    {
        moduleId: MODULE_ID_REQUEST_ROUTER,
        methods: [
            {
                name: 'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.NAME',
                methodName: METHOD_NAME_PING,
                description:
                    'APP.SHARED.SERVICES.INTERNAL.EDGE_STATIC_INFO_PROVIDER_DATA.DIRECT_METHOD.PING.DESCRIPTION',
            },
        ],
    },
];
