import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { filter, map, mapTo, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { PrimaryNavigationService } from '@priva/appshell';

import { AppCurrentUserService, UserPermission } from 'app/common/authorization/current-user.service';
import { UserSelector } from 'app/shared/user-state/state';

import { AppPrimaryNavigationService } from '../common/routing/primary-navigation.service';
import { initializeApp, logOut, navigateExternal, navigateInternal, resetState } from './app.actions';
import { AppState } from './app.state';

@Injectable({
    providedIn: 'root',
})
export class AppEffects {
    public initialized$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(initializeApp),
                tap(() => {}),
            );
        },
        { dispatch: false },
    );

    public logOut$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(logOut),
                tap(() => {
                    this.router.navigate(['signout']);
                }),
            );
        },
        { dispatch: false },
    );

    public routerNavigated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter(
                (action: RouterNavigatedAction) =>
                    action.payload.event.url === '/' || action.payload.event.url.startsWith('/?'),
            ),
            mapTo(resetState()),
        );
    });

    public loadCurrentUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter((action: RouterNavigatedAction) => action.payload.event.url === '/'),
            switchMap(() => {
                return this.currentUserService.hasPermission(UserPermission.Read).pipe(
                    take(1),
                    map((isInternal) => (isInternal ? navigateInternal() : navigateExternal())),
                );
            }),
        ),
    );

    public navigateInternal$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(navigateInternal),
                tap(() => {
                    this.router.navigate(['/gateway']);
                }),
            ),
        { dispatch: false },
    );

    public navigateExternal$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(navigateExternal),
                tap(() => {
                    this.router.navigate(['/external']);
                }),
            ),
        { dispatch: false },
    );

    public initializeNavigationItems$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(ROUTER_NAVIGATED),
                filter(
                    (action: RouterNavigatedAction) => !action.payload.routerState.url.startsWith('/error'),
                ),
                withLatestFrom(
                    this.store.select(UserSelector.isInternal),
                    this.store.select(UserSelector.isPartner),
                    this.store.select(UserSelector.isManageDiagnoseLogs),
                ),
                tap(([_action, isInternal, isPartner, isManageDiagnoseLogs]) => {
                    const navItems = [];
                    if (isPartner) {
                        navItems.push({
                            label: 'Partner',
                            label_short: 'partner',
                            slug: '',
                            url: `external/partner`,
                        });
                    }
                    if (isInternal) {
                        navItems.push(
                            {
                                label: 'Companies',
                                label_short: 'companies',
                                slug: '',
                                url: `/company`,
                            },
                            {
                                label: 'Gateways',
                                label_short: 'gateways',
                                slug: '',
                                url: `/gateway`,
                            },
                        );
                    }
                    if (isManageDiagnoseLogs) {
                        navItems.push({
                            label: 'Support',
                            label_short: 'support',
                            slug: '',
                            url: `/support`,
                        });
                    }

                    (this.navigationService as AppPrimaryNavigationService).navigation.next(navItems);
                }),
            );
        },
        { dispatch: false },
    );

    constructor(
        private actions$: Actions,
        private router: Router,
        private navigationService: PrimaryNavigationService,
        private currentUserService: AppCurrentUserService,
        private store: Store<AppState>,
    ) {}
}
