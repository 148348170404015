import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';

import { PartnerRestartModuleResult, PartnerWebSupportService } from 'app/shared/services/partner';
import { GatewayTypeEdge } from 'app/shared/services/web-support/gateway.constants';
import { AppState } from 'app/state';

import { InfrastructureModulesViewService } from '../services';
import { GatewayAction, GatewaySelector } from './';

@Injectable({
    providedIn: 'root',
})
export class GatewayEffects {
    constructor(
        private readonly actions$: Actions,
        private readonly store: Store<AppState>,
        private readonly webSupportService: PartnerWebSupportService,
        private readonly infrastructureModulesViewService: InfrastructureModulesViewService,
    ) {}

    public initializeInfrastructureModulesViews$ = createEffect(() => {
        return of(
            GatewayAction.initializeInfrastructureModuleViews({
                views: this.infrastructureModulesViewService.views,
            }),
        );
    });
    public initializeSelectedInfrastructureModulesViews$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GatewayAction.readGatewayDetails),
            withLatestFrom(this.store.select(GatewaySelector.getInfrastructureModuleViews)),
            switchMap(([_action, views]) => {
                return of(
                    GatewayAction.selectInfrastructureModuleView({
                        selectedViewId: views ? views[0].id : undefined,
                    }),
                );
            }),
        );
    });

    public readGatewayDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GatewayAction.readGatewayDetails),
            switchMap((action) => {
                return this.webSupportService.gateway.getGatewayDetailsByAssetId(action.assetId).pipe(
                    map((gatewayDetails) => {
                        if (!gatewayDetails) {
                            return GatewayAction.readGatewayDetailsFailed({
                                loadError: { message: 'Error reading the gateway datails' } as Error,
                            });
                        }
                        return GatewayAction.readGatewayDetailsSuccess({ gatewayDetails: gatewayDetails });
                    }),
                    catchError((error: Error) => {
                        return of(GatewayAction.readGatewayDetailsFailed({ loadError: error }));
                    }),
                );
            }),
        );
    });

    public triggerReadEdgeDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GatewayAction.readGatewayDetailsSuccess),
            filter((action) => action.gatewayDetails.gateway.type === GatewayTypeEdge),
            switchMap(() => of(GatewayAction.readEdgeGatewayDetails())),
        );
    });

    public readEdgeGatewayDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GatewayAction.readEdgeGatewayDetails),
            withLatestFrom(this.store.select(GatewaySelector.getSelectedGatewayId)),
            switchMap(([_action, gatewayId]) => {
                if (!gatewayId) {
                    return of(
                        GatewayAction.readEdgeGatewayDetailsFailed({
                            loadError: new Error('No gateway selected'),
                        }),
                    );
                }

                return this.webSupportService.gateway.getEdgeGatewayDetailsByGatewayId(gatewayId).pipe(
                    map((edgeGatewayDetails) => {
                        if (!edgeGatewayDetails) {
                            return GatewayAction.readEdgeGatewayDetailsFailed({
                                loadError: { message: 'Error reading the gateway datails' } as Error,
                            });
                        }
                        return GatewayAction.readEdgeGatewayDetailsSuccess({
                            edgeGatewayDetails: edgeGatewayDetails,
                        });
                    }),
                    catchError((error: Error) => {
                        return of(GatewayAction.readEdgeGatewayDetailsFailed({ loadError: error }));
                    }),
                );
            }),
        );
    });

    public triggerPingGateway$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GatewayAction.readGatewayDetailsSuccess),
            withLatestFrom(this.store.select(GatewaySelector.isEdgeGateway)),
            switchMap(([_action, isEdgeGateway]) => {
                return isEdgeGateway ? of(GatewayAction.pingGateway()) : EMPTY;
            }),
        );
    });

    public pingGateway$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GatewayAction.pingGateway),
            withLatestFrom(this.store.select(GatewaySelector.getSelectedGatewayId)),
            switchMap(([_action, gatewayId]) => {
                if (!gatewayId) {
                    return of(GatewayAction.pingGatewayFailed);
                }

                return this.webSupportService.gateway.pingGateway(gatewayId).pipe(
                    map((resultCode) => {
                        return GatewayAction.pingGatewaySuccess({ resultCode: resultCode });
                    }),
                    catchError((_error: Error) => {
                        return of(GatewayAction.pingGatewayFailed());
                    }),
                );
            }),
        );
    });

    public startDiagnose$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GatewayAction.startDiagnose),
            withLatestFrom(this.store.select(GatewaySelector.getSelectedGatewayId)),
            switchMap(([_action, gatewayId]) => {
                if (!gatewayId) {
                    return of(GatewayAction.startDiagnoseFailed);
                }

                this.webSupportService.gatewayDiagnose.startStream(gatewayId);
                return of(GatewayAction.startDiagnoseSuccess());
            }),
        );
    });

    public stopDiagnose$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(GatewayAction.stopDiagnose),
                tap(() => {
                    this.webSupportService.gatewayDiagnose.stopStream();
                }),
            );
        },
        { dispatch: false },
    );

    public triggerGetProjectTopology$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GatewayAction.readGatewayDetailsSuccess),
            withLatestFrom(this.store.select(GatewaySelector.isEdgeGateway)),
            switchMap(([_action, isEdgeGateway]) => {
                return isEdgeGateway ? of(GatewayAction.getProjectTopology()) : EMPTY;
            }),
        );
    });
    public getProjectTopology$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GatewayAction.getProjectTopology),
            withLatestFrom(this.store.select(GatewaySelector.getSelectedGatewayId)),
            switchMap(([_action, gatewayId]) => {
                if (!gatewayId) {
                    return of(GatewayAction.getProjectTopologyFailed);
                }

                return this.webSupportService.gateway.getProjectTopology(gatewayId).pipe(
                    map((projectTopology) => {
                        return GatewayAction.getProjectTopologySuccess({ projectTopology: projectTopology });
                    }),
                    catchError((_error: Error) => {
                        return of(GatewayAction.getProjectTopologyFailed());
                    }),
                );
            }),
        );
    });

    public triggerGetProjectType$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GatewayAction.readGatewayDetailsSuccess),
            withLatestFrom(this.store.select(GatewaySelector.isEdgeGateway)),
            switchMap(([_action, isEdgeGateway]) => {
                return isEdgeGateway ? of(GatewayAction.getProjectType()) : EMPTY;
            }),
        );
    });
    public getProjectType$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GatewayAction.getProjectType),
            withLatestFrom(this.store.select(GatewaySelector.getSelectedGatewayId)),
            switchMap(([_action, gatewayId]) => {
                if (!gatewayId) {
                    return of(GatewayAction.getProjectTypeFailed);
                }

                return this.webSupportService.gateway.getProjectType(gatewayId).pipe(
                    map((projectType) => {
                        return GatewayAction.getProjectTypeSuccess({ projectType: projectType });
                    }),
                    catchError((_error: Error) => {
                        return of(GatewayAction.getProjectTypeFailed());
                    }),
                );
            }),
        );
    });

    public restartModule$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(GatewayAction.restartModule),
            withLatestFrom(this.store.select(GatewaySelector.getSelectedGatewayId)),
            switchMap(([action, gatewayId]) => {
                if (!action.moduleId || !gatewayId) {
                    return of(GatewayAction.restartModuleFailed);
                }

                return this.webSupportService.gateway.restartModule(gatewayId, action.moduleId).pipe(
                    map((restartResult) => {
                        return GatewayAction.restartModuleSuccess({ restartResult: restartResult });
                    }),
                    catchError((_error: Error) => {
                        return of(
                            GatewayAction.restartModuleFailed({
                                restartResult: {} as PartnerRestartModuleResult,
                            }),
                        );
                    }),
                );
            }),
        );
    });
}
