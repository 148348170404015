import { createAction, props } from '@ngrx/store';

import { SupportLogDiagnoseDto } from 'app/shared/services/support/interfaces';

export const readLogDiagnoseItems = createAction('[Support] Read all log diagnose items');
export const readLogDiagnoseItemsSuccess = createAction(
    '[Support] Read all log diagnose items success',
    props<{ logDiagnoseItems: SupportLogDiagnoseDto[] }>(),
);
export const readLogDiagnoseItemsFailed = createAction(
    '[Support] Read all log diagnose items failed',
    props<{ loadError: Error }>(),
);

export const createLogDiagnoseItem = createAction(
    '[Support] Create a log diagnose item',
    props<{ logDiagnoseItem: SupportLogDiagnoseDto }>(),
);
export const createLogDiagnoseItemSuccess = createAction(
    '[Support] Create a log diagnose item success',
    props<{ logDiagnoseItem: SupportLogDiagnoseDto }>(),
);
export const createLogDiagnoseItemFailed = createAction(
    '[Support] Create a log diagnose item failed',
    props<{ loadError: Error }>(),
);

export const updateLogDiagnoseItem = createAction(
    '[Support] Update a log diagnose item',
    props<{ logDiagnoseItem: SupportLogDiagnoseDto }>(),
);
export const updateLogDiagnoseItemSuccess = createAction(
    '[Support] Update a log diagnose item success',
    props<{ logDiagnoseItem: SupportLogDiagnoseDto }>(),
);
export const updateLogDiagnoseItemFailed = createAction(
    '[Support] Update a log diagnose item failed',
    props<{ loadError: Error }>(),
);

export const deleteLogDiagnoseItem = createAction(
    '[Support] Delete a log diagnose item',
    props<{ logDiagnoseItem: SupportLogDiagnoseDto }>(),
);
export const deleteLogDiagnoseItemSuccess = createAction(
    '[Support] Delete a log diagnose item success',
    props<{ logDiagnoseItem: SupportLogDiagnoseDto }>(),
);
export const deleteLogDiagnoseItemFailed = createAction(
    '[Support] Delete a log diagnose item failed',
    props<{ loadError: Error }>(),
);
