import { Injectable } from '@angular/core';

import { EdgeGatewayModules, SystemInfrastructureModulesView } from '../enumerations';
import { InfrastructureModulesView } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class InfrastructureModulesViewService {
    public get views(): InfrastructureModulesView[] {
        return [
            {
                id: SystemInfrastructureModulesView.RealTimeData,
                label: 'APP.PARTNER.SYSTEM_OVERVIEW.VIEW.REAL_TIME_DATA.LABEL',
                modules: [
                    EdgeGatewayModules.edgeAgent,
                    EdgeGatewayModules.platformService,
                    EdgeGatewayModules.metricsCollector,
                    EdgeGatewayModules.edgeHub,
                    EdgeGatewayModules.rdcModule,
                    EdgeGatewayModules.requestRouter,
                    EdgeGatewayModules.httpConnector,
                    EdgeGatewayModules.comprinetConnector,
                    EdgeGatewayModules.metadata,
                ],
            },
            {
                id: SystemInfrastructureModulesView.HistoricalData,
                label: 'APP.PARTNER.SYSTEM_OVERVIEW.VIEW.HISTORICAL_DATA.LABEL',
                modules: [
                    EdgeGatewayModules.edgeAgent,
                    EdgeGatewayModules.edgeHub,
                    EdgeGatewayModules.platformService,
                    EdgeGatewayModules.metricsCollector,
                    EdgeGatewayModules.historian,
                    EdgeGatewayModules.metadata,
                ],
            },
            {
                id: SystemInfrastructureModulesView.Alarms,
                label: 'APP.PARTNER.SYSTEM_OVERVIEW.VIEW.ALARMS.LABEL',
                modules: [
                    EdgeGatewayModules.edgeAgent,
                    EdgeGatewayModules.edgeHub,
                    EdgeGatewayModules.platformService,
                    EdgeGatewayModules.metricsCollector,
                    EdgeGatewayModules.alarmModule,
                ],
            },
            {
                id: SystemInfrastructureModulesView.RemoteManagement,
                label: 'APP.PARTNER.SYSTEM_OVERVIEW.VIEW.REMOTE_MANAGEMENT.LABEL',
                modules: [
                    EdgeGatewayModules.edgeAgent,
                    EdgeGatewayModules.edgeHub,
                    EdgeGatewayModules.platformService,
                    EdgeGatewayModules.metricsCollector,
                    EdgeGatewayModules.remoteManagement,
                ],
            },
            {
                id: SystemInfrastructureModulesView.LocalFallback,
                label: 'APP.PARTNER.SYSTEM_OVERVIEW.VIEW.LOCAL_FALLBACK.LABEL',
                modules: [
                    EdgeGatewayModules.edgeAgent,
                    EdgeGatewayModules.httpConnector,
                    EdgeGatewayModules.auth,
                ],
            },
            {
                id: SystemInfrastructureModulesView.Updating,
                label: 'APP.PARTNER.SYSTEM_OVERVIEW.VIEW.UPDATING.LABEL',
                modules: [
                    EdgeGatewayModules.edgeAgent,
                    EdgeGatewayModules.edgeHub,
                    EdgeGatewayModules.platformService,
                    EdgeGatewayModules.metricsCollector,
                    EdgeGatewayModules.firmwareUpdater,
                ],
            },
        ];
    }
}
