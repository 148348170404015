import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { WebSupportSubService } from '../../web-support/web-support-sub.service';
import { SupportLogDiagnoseDto } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class LogDiagnoseService extends WebSupportSubService {
    constructor(http: HttpClient) {
        super(http);
    }

    public getAllLogDiagnoseItems(): Observable<SupportLogDiagnoseDto[]> {
        const url = `${this.getApiUrl()}/support/logdiagnose`;
        return this.http
            .get<SupportLogDiagnoseDto[]>(url, this.getDefaultRequestOptions())
            .pipe(catchError(this.handleError));
    }

    public createLogDiagnoseItem(logDiagnoseItem: SupportLogDiagnoseDto): Observable<SupportLogDiagnoseDto> {
        const url = `${this.getApiUrl()}/support/logdiagnose`;
        return this.http
            .post<SupportLogDiagnoseDto>(url, logDiagnoseItem, this.getDefaultRequestOptions())
            .pipe(catchError(this.handleError));
    }

    public updateLogDiagnoseItem(logDiagnoseItem: SupportLogDiagnoseDto): Observable<void> {
        if (!logDiagnoseItem?.moduleId || !logDiagnoseItem.id) {
            throw new Error('Cannot update log diagnose item: moduleId or id are not set');
        }

        const url = `${this.getApiUrl()}/support/logdiagnose/${logDiagnoseItem.moduleId}/${logDiagnoseItem.id}`;
        return this.http
            .put<void>(url, logDiagnoseItem, this.getDefaultRequestOptions())
            .pipe(catchError(this.handleError));
    }

    public deleteLogDiagnoseItem(logDiagnoseItem: SupportLogDiagnoseDto): Observable<void> {
        if (!logDiagnoseItem?.moduleId || !logDiagnoseItem.id) {
            throw new Error('Cannot delete log diagnose item: moduleId or id are not set');
        }

        const url = `${this.getApiUrl()}/support/logdiagnose/${logDiagnoseItem.moduleId}/${logDiagnoseItem.id}`;
        return this.http
            .delete<void>(url, this.getDefaultRequestOptions())
            .pipe(catchError(this.handleError));
    }
}
