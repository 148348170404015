import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PrivaBffLoggedInUserGuard } from '@priva/auth/current-user';
import { PrivaBrowserCheckGuard, PrivaUtilitiesModule } from '@priva/utilities';

import { AppComponent } from 'app/app.component';
import { AppConfigurationResolver } from 'app/common/configuration/app-configuration.resolver';

import { AppMainComponent } from './app-main.component';
import { AppGuard } from './app.guard';
import { UserPermission } from './common/authorization/current-user.service';

const routes: Routes = [
    {
        path: 'error',
        loadChildren: () =>
            import('./common/routing/error-pages.module').then((m) => m.AppErrorPagesLazyLoadedModule),
    },
    {
        path: '',
        resolve: {
            config: AppConfigurationResolver,
        },
        component: AppComponent,
        canActivate: [PrivaBffLoggedInUserGuard],
        children: [
            {
                path: 'signout',
                loadChildren: () => import('@priva/auth/signout').then((m) => m.PrivaAuthSignoutModule),
            },
            {
                path: '',
                canActivate: [PrivaBrowserCheckGuard],
                component: AppMainComponent,
                children: [
                    {
                        path: 'company',
                        canActivate: [PrivaBrowserCheckGuard, AppGuard],
                        data: {
                            appGuard: AppGuard.routeData({
                                permission: UserPermission.Read,
                                redirectUrl: '/error/access-denied',
                            }),
                        },
                        loadChildren: () =>
                            import('./modules/tenant/tenant.module').then((m) => m.TenantModule),
                    },
                    {
                        path: 'gateway',
                        canActivate: [PrivaBrowserCheckGuard, AppGuard],
                        data: {
                            appGuard: AppGuard.routeData({
                                permission: UserPermission.Read,
                                redirectUrl: '/error/access-denied',
                            }),
                        },
                        loadChildren: () =>
                            import('./modules/gateway/gateway.module').then((m) => m.GatewayModule),
                    },
                    {
                        path: 'site',
                        canActivate: [PrivaBrowserCheckGuard, AppGuard],
                        data: {
                            appGuard: AppGuard.routeData({
                                permission: UserPermission.Read,
                                redirectUrl: '/error/access-denied',
                            }),
                        },
                        loadChildren: () => import('./modules/asset/asset.module').then((m) => m.AssetModule),
                    },
                    {
                        path: 'support',
                        canActivate: [PrivaBrowserCheckGuard, AppGuard],
                        data: {
                            appGuard: AppGuard.routeData({
                                permission: UserPermission.ManageDiagnoseLogs,
                                redirectUrl: '/error/access-denied',
                            }),
                        },
                        loadChildren: () =>
                            import('./modules/support/support.module').then((m) => m.SupportModule),
                    },
                    {
                        path: 'external',
                        canActivate: [PrivaBrowserCheckGuard, AppGuard],
                        data: {
                            appGuard: AppGuard.routeData({
                                permission: UserPermission.PartnerRead,
                                redirectUrl: '/error/access-denied',
                            }),
                        },
                        children: [
                            {
                                path: '',
                                pathMatch: 'full',
                                redirectTo: 'partner',
                            },
                            {
                                path: 'partner',
                                loadChildren: () =>
                                    import('./modules/partner/partner.module').then((m) => m.PartnerModule),
                            },
                        ],
                    },
                ],
            },
        ],
    },
    { path: '**', redirectTo: 'error/not-found', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), PrivaUtilitiesModule],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
