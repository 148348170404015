import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ContentContainerState } from 'app/shared/components/content-container/content-container.model';
import { FeatureName } from 'app/state';

import { LogDiagnoseState } from './log-diagnose.state';

const getLogDiagnoseFeatureState = createFeatureSelector<LogDiagnoseState>(FeatureName.LogDiagnose);

export const getLogDiagnoseItems = createSelector(
    getLogDiagnoseFeatureState,
    (state: LogDiagnoseState) => state.logDiagnoseItems,
);
export const getLoadingState = createSelector(getLogDiagnoseFeatureState, (state: LogDiagnoseState) => {
    return {
        isLoading: state.isLoading,
        loadingFailed: state.hasLoadingFailed,
    } as ContentContainerState;
});

export const getLoadError = createSelector(
    getLogDiagnoseFeatureState,
    (state: LogDiagnoseState) => state.loadError,
);
