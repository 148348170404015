import {
    MODULE_ID_ALARM_MODULE,
    MODULE_ID_AUTH,
    MODULE_ID_COMPRINET_CONNECTOR,
    MODULE_ID_CONNEXTOR,
    MODULE_ID_DDS_CONNECTOR,
    MODULE_ID_EDGE_AGENT,
    MODULE_ID_EDGE_HUB,
    MODULE_ID_FIRMWARE_UPDATER,
    MODULE_ID_HISTORIAN,
    MODULE_ID_HTTP_CONNECTOR,
    MODULE_ID_LEAF_DEVICE_MANAGER,
    MODULE_ID_METADATA,
    MODULE_ID_METADATA_SERVICE,
    MODULE_ID_METRICS_COLLECTOR,
    MODULE_ID_MQTT_CONNECTOR,
    MODULE_ID_OPERATOR_API,
    MODULE_ID_PLATFORM_SERVICE,
    MODULE_ID_RDC_MODULE,
    MODULE_ID_REMOTE_MANAGEMENT,
    MODULE_ID_REQUEST_ROUTER,
    MODULE_ID_SYSTEM_AGENT,
} from 'app/shared/services/internal/edge-static-info/edge-static-info-provider.data';

export enum EdgeGatewayModules {
    edgeAgent = MODULE_ID_EDGE_AGENT,
    edgeHub = MODULE_ID_EDGE_HUB,
    alarmModule = MODULE_ID_ALARM_MODULE,
    comprinetConnector = MODULE_ID_COMPRINET_CONNECTOR,
    connextor = MODULE_ID_CONNEXTOR,
    ddsConnector = MODULE_ID_DDS_CONNECTOR,
    firmwareUpdater = MODULE_ID_FIRMWARE_UPDATER,
    historian = MODULE_ID_HISTORIAN,
    httpConnector = MODULE_ID_HTTP_CONNECTOR,
    leafDeviceManager = MODULE_ID_LEAF_DEVICE_MANAGER,
    metadata = MODULE_ID_METADATA,
    metadataService = MODULE_ID_METADATA_SERVICE,
    mqttConnector = MODULE_ID_MQTT_CONNECTOR,
    operatorApi = MODULE_ID_OPERATOR_API,
    platformService = MODULE_ID_PLATFORM_SERVICE,
    rdcModule = MODULE_ID_RDC_MODULE,
    remoteManagement = MODULE_ID_REMOTE_MANAGEMENT,
    requestRouter = MODULE_ID_REQUEST_ROUTER,
    systemAgent = MODULE_ID_SYSTEM_AGENT,
    auth = MODULE_ID_AUTH,
    metricsCollector = MODULE_ID_METRICS_COLLECTOR,
}
