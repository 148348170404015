import { PartnerRestartModuleResult, ProjectTopology } from 'app/shared/services/partner';
import { ProjectType } from 'app/shared/services/partner/enumerations';
import { GatewayDiagnoseResult } from 'app/shared/services/partner/interfaces/gateway-diagnose-result.interface';
import { PlatformServiceReportedPropertiesDto } from 'app/shared/services/web-support/edge/platformService.model';
import { EdgeGatewayDetailsDto, GatewayDetailsDto } from 'app/shared/services/web-support/gateway.model';
import * as appState from 'app/state/app.state';

import { InfrastructureModulesView } from '../interfaces';

export interface AppState extends appState.AppState {
    GatewayState: GatewayState;
}

export interface GatewayState {
    edgeGatewayDetails: EdgeGatewayDetailsDto | undefined;
    selectedGateway: GatewayDetailsDto | undefined;
    platformServiceReportedProperties: PlatformServiceReportedPropertiesDto | undefined;
    isLoading: boolean;
    isLoadingDetails: boolean;
    hasLoadingFailed: boolean;
    loadError: Error | undefined;
    isPingingEdgeAgent: boolean;
    pingEdgeAgentResult: number;
    isDiagnosing: boolean;
    diagnoseResults: { [moduleId: string]: GatewayDiagnoseResult };
    selectedDiagnoseResult: GatewayDiagnoseResult | undefined;
    infrastructureModulesViews: InfrastructureModulesView[];
    selectedInfrastructureModuleView: InfrastructureModulesView | undefined;
    projectTopology: ProjectTopology | undefined;
    isLoadingProjectTopology: boolean;
    hasLoadingProjectTopologyFailed: boolean;
    projectType: ProjectType | undefined;
    isRestartingModule: boolean;
    restartModuleResult: PartnerRestartModuleResult | undefined;
}

export const initialGatewayState: GatewayState = {
    selectedGateway: undefined,
    edgeGatewayDetails: undefined,
    platformServiceReportedProperties: undefined,
    isLoading: false,
    isLoadingDetails: false,
    hasLoadingFailed: false,
    loadError: undefined,
    isPingingEdgeAgent: false,
    pingEdgeAgentResult: 0,
    isDiagnosing: false,
    diagnoseResults: {},
    selectedDiagnoseResult: undefined,
    infrastructureModulesViews: [],
    selectedInfrastructureModuleView: undefined,
    projectTopology: undefined,
    isLoadingProjectTopology: false,
    hasLoadingProjectTopologyFailed: false,
    projectType: undefined,
    isRestartingModule: false,
    restartModuleResult: undefined,
};
